import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable'

$(document).ready(() => {

    //Position sortable
    $('.sortable tbody').sortable({
        revert: true,
        cursor: 'grab',
        handle: '.move',
        placeholder: "ui-state-highlight",
        items: "> tr",
        axis: "y",
        opacity: 0.8,
        update: function (event, ui) {
            var data = {
                'table': $('.sortable').data('table'),
                'ids': {}
            };

            $(this).find('.entry').each(function (i, n) {
                data['ids'][i] = $(this).data('id');
            });

            $.ajax({
                type: 'POST',
                url: ajaxPositionUrl,
                data: data
            });

        }
    });

});