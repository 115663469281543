// Imports
import $ from "jquery";
import 'bootstrap';
import 'metismenu';
import './ladda-loading.js';
import './blockui.js';
import './circle-progress.js';
import './count-up.js';
import './toastr.js';
import './sweet-alerts.js';
import './scrollbar.js';
import './sticky-elements.js';
import './carousel-slider.js';
import './calendar.js';
import './treeview.js';
import './rating.js';
import './image-crop.js';
import './guided-tours.js';
import './tables.js';
import './form-components/form-wizard.js';
import './form-components/clipboard.js';
import './form-components/datepicker.js';
import './form-components/input-mask.js';
import './form-components/input-select.js';
import './form-components/range-slider.js';
import './form-components/textarea-autosize.js';
import './form-components/toggle-switch.js';
import './charts/chartjs.js';
import './charts/apex-charts.js';
import './charts/charts-sparklines.js';
import './custom/form-collection.js';
import './custom/dashboard-grid.js';
import './custom/sortable.js';

// Stylesheets
require('../../css/backend/app.scss');

$(document).ready(() => {

    // Sidebar Menu

    setTimeout(function () {
        $(".vertical-nav-menu").metisMenu();
    }, 100);

    // Search wrapper trigger

    $('.search-icon').click(function () {
        $(this).parent().parent().parent().addClass('active');
    });

    $('.search-wrapper .close').click(function () {
        $(this).parent().parent().removeClass('active');
    });


    // BS4 Popover

    $('[data-toggle="popover-custom-content"]').each(function (i, obj) {

        $(this).popover({
            html: true,
            placement: 'auto',
            template: '<div class="popover popover-custom" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
            content: function () {
                var id = $(this).attr('popover-id');
                return $('#popover-content-' + id).html();
            }
        });

    });

    // Stop Bootstrap 4 Dropdown for closing on click inside

    $('.dropdown-menu').on('click', function (event) {
        var events = $._data(document, 'events') || {};
        events = events.click || [];
        for (var i = 0; i < events.length; i++) {
            if (events[i].selector) {

                if ($(event.target).is(events[i].selector)) {
                    events[i].handler.call(event.target, event);
                }

                $(event.target).parents(events[i].selector).each(function () {
                    events[i].handler.call(this, event);
                });
            }
        }
        event.stopPropagation(); //Always stop propagation
    });


    $('[data-toggle="popover-custom-bg"]').each(function (i, obj) {

        var popClass = $(this).attr('data-bg-class');

        $(this).popover({
            trigger: 'focus',
            placement: 'top',
            template: '<div class="popover popover-bg ' + popClass + '" role="tooltip"><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        });

    });

    $(function () {
        $('[data-toggle="popover"]').popover();
    });

    $('[data-toggle="popover-custom"]').each(function (i, obj) {

        $(this).popover({
            html: true,
            container: $(this).parent().find('.rm-max-width'),
            content: function () {
                return $(this).next('.rm-max-width').find('.popover-custom-content').html();
            }
        });
    });

    $('body').on('click', function (e) {
        $('[rel="popover-focus"]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

    $('.header-megamenu.nav > li > .nav-link').on('click', function (e) {
        $('[data-toggle="popover-custom"]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

    // BS4 Tooltips

    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });

    $(function () {
        $('[data-toggle="tooltip-light"]').tooltip({
            template: '<div class="tooltip tooltip-light"><div class="tooltip-inner"></div></div>'
        });
    });

    // Drawer

    $('.open-right-drawer').click(function () {
        $(this).addClass('is-active');
        $('.app-drawer-wrapper').addClass('drawer-open');
        $('.app-drawer-overlay').removeClass('d-none');
    });

    $('.drawer-nav-btn').click(function () {
        $('.app-drawer-wrapper').removeClass('drawer-open');
        $('.app-drawer-overlay').addClass('d-none');
        $('.open-right-drawer').removeClass('is-active');
    });

    $('.app-drawer-overlay').click(function () {
        $(this).addClass('d-none');
        $('.app-drawer-wrapper').removeClass('drawer-open');
        $('.open-right-drawer').removeClass('is-active');
    });

    $('.mobile-toggle-nav').click(function () {
        $(this).toggleClass('is-active');
        $('.app-container').toggleClass('sidebar-mobile-open');
    });

    $('.mobile-toggle-header-nav').click(function () {
        $(this).toggleClass('active');
        $('.app-header__content').toggleClass('header-mobile-open');
    });

    $('.mobile-app-menu-btn').click(function () {
        $('.hamburger', this).toggleClass('is-active');
        $('.app-inner-layout').toggleClass('open-mobile-menu');
    });

    // Responsive

    $(window).on('resize', function(){
        var win = $(this);
        if (win.width() < 1250) {
            $('.app-container').addClass('closed-sidebar-mobile closed-sidebar');
        }
        else
        {
            $('.app-container').removeClass('closed-sidebar-mobile closed-sidebar');
        }
    });

    // Projects Ajax
    $('#timesheet_status').on('change', function() {
        initProjects($(this));
    });

    initProjects($('#timesheet_status'));

    function initProjects(element) {
        var ajaxUrl = element.closest('form').data('status-url');
        element.closest('form').find('.timesheet_quotationLine').html();

        $.ajax({
            url: ajaxUrl,
            type: 'GET',
            dataType: 'html',
            data: {status: $('#timesheet_status').prop('checked')},
            success: function (code_html, status) {
                var projectLinesSelect = JSON.parse(code_html);
                element.closest('form').find('.timesheet_project').html(projectLinesSelect).change();
            }
        });
    }

    // Project Ajax
    $('.timesheet_project').on('change', function() {
       var projectId = $(this).val();
       var ajaxUrl = $(this).closest('form').data('url');
       var quotationLineId = $(this).attr('data-quotation-line-id');
       var element = $(this);

       if (projectId.length > 0) {
           $.ajax({
               url: ajaxUrl,
               type: 'GET',
               dataType: 'html',
               data: {id: projectId, status: $('#timesheet_status').prop('checked')},
               success: function (code_html, status) {
                   var quotationLinesSelect = JSON.parse(code_html);
                   element.closest('form').find('.timesheet_quotationLine').html(quotationLinesSelect).change();
                   if (typeof quotationLineId != 'undefined') {
                       element.closest('form').find('.timesheet_quotationLine option').removeAttr('selected');
                       element.closest('form').find('.timesheet_quotationLine option[value="'+quotationLineId+'"]').attr('selected','selected').parent().select2().trigger('change');
                       element.removeAttr('data-quotation-line-id');
                   }
               }
           });
       }
    });

    // Type de devis
    $('.quotation-type').each(function() {
        quotationType($(this));
    });
    $('body').on('change', '.quotation-type', function() {
        quotationType($(this));
    });

    let cptInformationLine = 1;

    $('#code-save').on('click', function() {
        var codeCategoryId = $('#code-category').val();
        var codeLabel = $('#code-label').val();
        var codeValue = $('#code-value').val();
        var codeProjectId = $('#code-project-id').val();
        var codeUrl = $('#addCode').data('url');

        var informations = [];

        for (let i = 0; i < cptInformationLine; i++) {
            informations[i] = {'label': $('#code-label-'+i).val(), 'value': $('#code-value-'+i).val()};
        }

        $.ajax({
            url: codeUrl,
            type: 'POST',
            dataType: 'json',
            data: {'informations': informations, 'codeCategoryId': codeCategoryId, 'codeProjectId': codeProjectId},
            success: function (code_html, status) {
                location.reload();
            }
        });
    });

    $('.information-add-line').on('click', function() {
        let $newLine = $('.add-line-point-start').clone();
        $newLine.html($newLine.html().replace(new RegExp('-X', 'g'), '-'+cptInformationLine));
        $newLine.find('.toRemove').remove();
        $newLine.find('select').addClass('multiselect-dropdown');
        $newLine.find('.multiselect-dropdown').select2({
            theme: "bootstrap4"
        });

        $newLine.removeClass('add-line-point-start').show().insertBefore($('.add-line-point'));

        cptInformationLine ++;
    });

    $('#user-widget-save').on('click', function() {
        var userWidgetLink = $('#user-widget-link-link').val();
        var userWidgetLinkUrl = $('#user-widget-link-url').val();
        var userWidgetAddPath = $('#addUserWidgetLink').data('url');

        $.ajax({
            url: userWidgetAddPath,
            type: 'POST',
            dataType: 'json',
            data: {'userWidgetLink': userWidgetLink, 'userWidgetLinkUrl': userWidgetLinkUrl},
            success: function (code_html, status) {
                location.reload();
            }
        });
    });

    $('body').on('click', '.widget-project-timer', function() {
       var projectId = $(this).data('id');
       $('.app-drawer-wrapper .timesheet_project option').removeAttr('selected');
       $('.app-drawer-wrapper .timesheet_project option[value="'+projectId+'"]').attr('selected','selected').trigger("change");
       $('.app-drawer-wrapper').addClass('drawer-open');
    });

    $('body').on('click', '.widget-quotation-line-timer', function() {
        var projectId = $(this).data('project-id');
        var timerQuotationLineId = $(this).data('quotation-line-id');
        $('.app-drawer-wrapper .timesheet_project').attr('data-quotation-line-id', timerQuotationLineId);
        $('.app-drawer-wrapper .timesheet_project option').removeAttr('selected');
        $('.app-drawer-wrapper .timesheet_project option[value="'+projectId+'"]').attr('selected','selected').trigger("change");

        $('.app-drawer-wrapper').addClass('drawer-open');
    });

    $('.user-widget-link-delete-mode').on('click', function(e) {
        e.preventDefault();
       if ($('.user-widget-links').hasClass('deleted-mode')) {
           // On désactive le mode suppression
           $('.user-widget-links').removeClass('deleted-mode').find('.badge').hide();
           $('.user-widget-link-delete-mode').removeClass('active');
       }  else {
           // On active le mode suppression
           $('.user-widget-links').addClass('deleted-mode').find('.badge').show();
           $('.user-widget-link-delete-mode').addClass('active');
       }
    });

    $('body').on('click', '.user-widget-links.deleted-mode a', function(e) {
        e.preventDefault();

        var linkId = $(this).data('link-id');
        var userWidgetId = $(this).data('user-widget-id');
        var userWidgetLinkDeletePath = $('.user-widget-links').data('url');

        $.ajax({
            url: userWidgetLinkDeletePath,
            type: 'POST',
            dataType: 'json',
            data: {'linkId': linkId, 'userWidgetId': userWidgetId},
            success: function (code_html, status) {
                location.reload();
            }
        });

        return false;
    });

    $('.voter input').on('change', function() {
        var element = $(this);
        $.ajax({
            url: element.closest('.voter').data('url'),
            type: 'POST',
            dataType: 'json',
            data: {'voter': element.closest('.voter').data('id'), 'name': element.attr('name'), 'value': element.is(":checked")},
            success: function (code_html, status) {
            }
        });
    });

    // Widget profit
    $('body').on('click', '.widget-sensible-view', function() {
        $(this).next().show();
       $(this).hide();
    });

});

function quotationType($quotationType) {
    if ($quotationType.val() == 2) {
        var myDate = $quotationType.parent().find('.quotation-type-date');
        myDate.show();
        myDate.prev().show();
    } else {
        var myDate = $quotationType.parent().find('.quotation-type-date');
        myDate.hide();
        myDate.prev().hide();
    }
}

