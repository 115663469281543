import 'symfony-collection';
import $ from "jquery";

$(document).ready(() => {

    if ($('.customer_contacts').length > 0) {
        $('.customer_contacts').collection({
            name_prefix: 'customer_contacts',
            allow_up: true,
            allow_down: true,
            allow_add: true,
            allow_remove: true,
            before_remove: function(collection, element) { return confirm("Etes-vous sur de vouloir supprimer cet élément ?"); },
            allow_duplicate: false,
            add_at_the_end: true,
            drag_drop: true,
            up: '',
            down: '',
            add: '<div class="col-md-12"><button type="button" class="btn-icon btn btn-light"><i class="icon ion-android-add"> </i>Ajouter un contact</button></div>',
            remove: '',
            position_field_selector: '.customer_contacts-position',
            after_add: function(collection, element) {
                $(element).find('.multiselect-dropdown').select2({
                    theme: "bootstrap4"
                });
            }
        });
    }

    if ($('.attach_links').length > 0) {
        $('.attach_links').collection({
            name_prefix: 'attach_links',
            allow_up: true,
            allow_down: true,
            allow_add: true,
            allow_remove: true,
            before_remove: function(collection, element) { return confirm("Etes-vous sur de vouloir supprimer cet élément ?"); },
            allow_duplicate: false,
            add_at_the_end: true,
            drag_drop: true,
            up: '',
            down: '',
            add: '<div class="col-md-12"><button type="button" class="btn-icon btn btn-light"><i class="icon ion-android-add"> </i>Ajouter un lien</button></div>',
            remove: '',
            position_field_selector: '.attach_links-position',
            after_add: function(collection, element) {
                select2Element($(element).find('.multiselect-dropdown-with-tag'));
            }
        });
    }

    if ($('.project_codes').length > 0) {
        $('.project_codes').collection({
            name_prefix: 'project_codes',
            allow_up: true,
            allow_down: true,
            allow_add: true,
            allow_remove: true,
            before_remove: function(collection, element) { return confirm("Etes-vous sur de vouloir supprimer cet élément ?"); },
            allow_duplicate: false,
            add_at_the_end: true,
            drag_drop: true,
            up: '',
            down: '',
            add: '<div class="col-md-12"><button type="button" class="btn-icon btn btn-light"><i class="icon ion-android-add"> </i>Ajouter un code</button></div>',
            remove: '',
            position_field_selector: '.project_codes-position',
            after_add: function(collection, element) {
                select2Element($(element).find('.multiselect-dropdown-with-tag'));
            }
        });
    }

    if ($('.quotations').length > 0) {
        $('.quotations').collection({
            selector: 'quotations',
            name_prefix: 'project[quotations]',
            allow_up: false,
            allow_down: false,
            allow_add: true,
            allow_remove: true,
            before_remove: function(collection, element) { return confirm("Etes-vous sur de vouloir supprimer cet élément ?"); },
            allow_duplicate: false,
            add_at_the_end: true,
            drag_drop: false,
            up: '',
            down: '',
            add: '<div class="col-md-12"><button type="button" class="btn-icon btn btn-light"><i class="icon ion-android-add"> </i>Ajouter un devis</button></div>',
            remove: '',
            position_field_selector: '.quotations-position',
            after_add: function(collection, element) {
                select2Element($(element).find('.multiselect-dropdown-with-tag'));
                $('.quotation-type').each(function() {
                    if ($(this).val() == 2) {
                        var myDate = $(this).parent().find('.quotation-type-date');
                        myDate.show();
                        myDate.prev().show();
                    } else {
                        var myDate = $(this).parent().find('.quotation-type-date');
                        myDate.hide();
                        myDate.prev().hide();
                    }
                });
                $('[data-toggle="datepicker"]').datepicker({
                    'format': 'yyyy-mm-dd',
                    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                    daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                    monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Dec'],
                    weekStart: 1,
                    autoHide: true
                });
            },
            children: [{
                selector: '.quotations_lines',
                name_prefix: 'project[quotations][0][quotationLines][1]',
                prototype_name: '__children__',
                allow_up: true,
                allow_down: true,
                allow_add: true,
                allow_remove: true,
                before_remove: function(collection, element) { return confirm("Etes-vous sur de vouloir supprimer cet élément ?"); },
                allow_duplicate: false,
                add_at_the_end: true,
                drag_drop: true,
                up: '',
                down: '',
                add: '<div class="col-md-12"><button type="button" class="btn-icon btn btn-light"><i class="icon ion-android-add"> </i>Ajouter une ligne</button></div>',
                remove: '',
                position_field_selector: '.quotations_lines-position',
                after_add: function(collection, element) {
                    $(element).find('input[type=checkbox]').bootstrapToggle();
                    select2Element($(element).find('.multiselect-dropdown-with-tag'));
                }
            }]
        });
    }
});

function select2Element(element) {
    element.select2({
        theme: "bootstrap4",
        tags: true,
        casesensitive: false,
        matcher: function(params, data) {
            // If there are no search terms, return all of the data
            if ($.trim(params.term) === '') {
                return data;
            }

            // `params.term` should be the term that is used for searching
            // `data.text` is the text that is displayed for the data object
            if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                return data;
            }

            // Return `null` if the term should not be displayed
            return null;
        },
        createTag: function(params) {
            var term = $.trim(params.term);
            if(term === "") { return null; }

            var optionsMatch = false;

            this.$element.find("option").each(function() {
                if(this.value.toLowerCase().indexOf(term.toLowerCase()) > -1) {
                    optionsMatch = true;
                }
            });

            if(optionsMatch) {
                return null;
            }
            return {id: term, text: term};
        }
    });
}