// Sliders & Carousels

import '@fullcalendar/common/main.min.css';
import { Calendar } from '@fullcalendar/core';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import $ from "jquery";


$(document).ready(() => {

    if ($('#calendar').length > 0) {

        var calendarEl = document.getElementById('calendar');

        var calendar = new Calendar(calendarEl, {
            plugins: [ dayGridPlugin, interactionPlugin, bootstrapPlugin ],
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,dayGridWeek,dayGridDay'
            },
            themeSystem: 'bootstrap4',
            firstDay: 1,
            bootstrapFontAwesome: true,
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            droppable: false,
            eventLimit: true, // allow "more" link when too many events
            eventLimitText: "More", //sets the text for more events
            locale: 'fr',
            timeZone: 'Europe/Paris',
            events: {
                url: $('#calendar').data('url'),
                type: 'POST',
                cache: true
            },
            eventClick:  function(info, jsEvent, view) {
                $('#modalTitle').html(info.event.title);
                $('#modalBody').html(info.event.extendedProps.description);
                $('#fullCalModal').modal();
            },
            eventDrop: function(eventDropInfo) {
                let myEvent = jQuery(eventDropInfo.el.innerHTML);
                let eventId = myEvent.find('.timesheet-title').attr('data-id');
                $.ajax({
                    url: $('#calendar').data('change-date-url'),
                    type: 'GET',
                    dataType: 'html',
                    data: {id: eventId, date: eventDropInfo.event.start.toISOString()},
                    success: function (code_html, status) {
                    }
                });
            },
            dateClick: function(dateClickInfo) {
                $('.app-drawer-wrapper #timesheet_workedAt').val(dateClickInfo.dateStr);
                $('.app-drawer-wrapper').addClass('drawer-open');
            },
            buttonText: {
                today: 'Ajourd\'hui',
                month: 'Mois',
                week: 'Semaine',
                day: 'Jour'
            },
            eventContent: function(eventInfo) {
                return { html: eventInfo.event.title }
            }
        });

        calendar.render();

    }

});