// Forms Datepicker

import '@chenfengyuan/datepicker';
import 'daterangepicker';
import moment from 'moment';
import $ from "jquery";

$(document).ready(() => {
    // Datepicker
    $('[data-toggle="datepicker"]').datepicker({
        'format': 'yyyy-mm-dd',
        days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekStart: 1,
        autoHide: true
    });
});