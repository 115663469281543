// Forms Multi Select

import 'select2';

$(document).ready(() => {

    setTimeout(function () {
        $(".multiselect-dropdown").select2({
            theme: "bootstrap4",
            matcher: function(params, data) {
                var original_matcher = $.fn.select2.defaults.defaults.matcher;
                var result = original_matcher(params, data);
                if (result && data.children && result.children && data.children.length != result.children.length) {
                    result.children = data.children;
                }
                return result;
            }
        });

        $(".multiselect-dropdown-with-tag").select2({
            theme: "bootstrap4",
            tags: true,
            casesensitive: false,
            matcher: function(params, data) {
                // If there are no search terms, return all of the data
                if ($.trim(params.term) === '') {
                    return data;
                }

                // `params.term` should be the term that is used for searching
                // `data.text` is the text that is displayed for the data object
                if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                    return data;
                }

                // Return `null` if the term should not be displayed
                return null;
            },
            createTag: function(params) {
                var term = $.trim(params.term);
                if(term === "") { return null; }

                var optionsMatch = false;

                this.$element.find("option").each(function() {
                    if(this.value.toLowerCase().indexOf(term.toLowerCase()) > -1) {
                        optionsMatch = true;
                    }
                });

                if(optionsMatch) {
                    return null;
                }
                return {id: term, text: term};
            }
        });

    }, 2000);

});