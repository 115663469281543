// Form Wizard

import 'smartWizard';

$(document).ready(() => {

    setTimeout(function () {

        $('#smartwizard').smartWizard({
            selected: 0,
            transitionEffect: 'fade',
            toolbarSettings: {
                toolbarPosition: 'none',
            },
            keyNavigation: false
        });

        // External Button Events
        $("#reset-btn").on("click", function () {
            // Reset wizard
            $('#smartwizard').smartWizard("reset");
            return true;
        });

        $("#prev-btn").on("click", function () {
            // Navigate previous
            $('#smartwizard').smartWizard("prev");
            return true;
        });

        $("#next-btn").on("click", function () {
            // Navigate next
            $('#smartwizard').smartWizard("next");
            return true;
        });

    }, 2000);
});