import 'gridstack/dist/gridstack.all.js'
import 'jquery-ui/ui/data'
import $ from "jquery";
import CountUp from "countup.js";

$(document).ready(() => {

    // Init Gridstack
    var options = {
        draggable: {
            handle: '.card-header'
        }
    };
    $('.grid-stack').gridstack(options);
    var grid = $('.grid-stack').data('gridstack');

    // ---- Drag & Drop évènement ----
    $('.grid-stack').on('change', function(e, items){
        var datas = [];

        if (typeof items != 'undefined') {
            items.forEach(function(element, n){
                datas[n] = {'x': element.x, 'y': element.y, 'w': element.width, 'h': element.height, 'id': element.el.data('id')};
            });
            savePosition(datas);
        }
    });

    $('.gridstack-delete-btn').on('click', function() {
        var itemId = $(this).data('id');
        $('#gridstack-delete').attr('data-id', itemId);
    });

    // ---- Supprime le widget ----
    $('.gridstack-delete').on('click', function(){
        var id = $('#gridstack-delete').attr('data-id');
        var item = $('.grid-stack-item[data-id='+id+']');

        // ---- Requete AJAX----
        $.ajax({
            type: 'POST',
            url: $('.grid-stack').data('delete')+'/'+id,
            dataType: 'json',
            success: function (data) {
                grid.removeWidget(item);
                $('#deleteWidget').modal('toggle');
            },
            error: function (jqXHR) {
            },
        });

        return false;
    });

    // ---- Get datas ----
    $('.grid-stack-item').each(function(i, n){
        calculDatas(this, 0);
    });

    // ---- Change options ----
    $('.options-submit').on('click', function(){

        // ---- Récupère les données ----
        calculDatas($(this).closest('.grid-stack-item'), 1);

        // ---- Close options box ----
        $(this).closest('.grid-stack-item').find('span[data-toggle=dropdown]').dropdown('hide')
    });

});

// ---- Sauvegarder la position des widgets en AJAX ----
function savePosition(datas)
{
    if (datas.length > 0) {
        $.ajax({
            type: 'POST',
            url: $('.grid-stack').data('save'),
            dataType: 'json',
            data: {'datas' : datas},
            success: function (data) {
            },
            error: function (jqXHR) {
            },
        });
    }
}

// ---- Envoie la requete AJAX pour récupérer les données ----
function calculDatas(that, saveOptions=0)
{
    // ---- Id du widget----
    var widgetId = $(that).data('id');

    // ---- Récupération des options ----
    var myForm = $(that).find('form');
    var options = null;
    if ($(that).find('.options-btn').length) {
        options = myForm.serialize();
    }

    var loader='<div class="p-4 d-flex justify-content-center align-items-center">\
        <div class="loader">\
            <div class="ball-scale-multiple">\
                <div style="background-color: #30c7ec;"></div>\
                <div style="background-color: #30c7ec;"></div>\
                <div style="background-color: #30c7ec;"></div>\
            </div>\
        </div>\
    </div>';

    $(that).find('.card-body').html(loader);

    // ---- Requete AJAX----
    $.ajax({
        type: 'POST',
        url: $(that).data('datas')+'/'+widgetId,
        dataType: 'html',
        data: {'options': options, 'saveOptions': saveOptions},
        success: function (datas) {
            $(that).find('.card-body').html(datas);
            postAjax();
        }
    });
}

function podiumAnimate() {
    $('.bronze .podium').animate({
        "height": "62px"
    }, 1500);
    $('.gold .podium').animate({
        "height": "165px"
    }, 1500);
    $('.silver .podium').animate({
        "height": "106px"
    }, 1500);
    $('.competition-container .name').delay(1000).animate({
        "opacity": "1"
    }, 500);
}

function postAjax()
{
    podiumAnimate();
    $('[data-toggle="tooltip"]').tooltip();
}